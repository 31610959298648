import $ from 'jquery';
import 'slick-carousel';
import 'picturefill';

export default () => {



  $(() => {
    
    // jQuery Code Here

    /**
     * Slick Carousel
     */
    $('.hero__slider').slick({
      dots: true,
      arrows: false,
      lazyLoad: 'ondemand',
      autoplay: true,
      autoplaySpeed: 3000
    });

    // 関連商品3つ以下の場合は、slickを適用しない
    const item = $('.product__slider .l-column__item'),
          cnt = item.length;

    if (cnt > 3 ) {
      $('.product__slider').slick({
        dots: false,
        arrows: true,
        lazyLoad: 'ondemand',
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [{
          breakpoint: 768,
          settings: 'unslick'
        }]
      });
    } else {
      if (window.matchMedia('(max-width:768px)').matches) {
        item.css('padding', '0');
      } else {
        item.css('padding', '0 1.85%');
      }
    }
    
    $(window).on('resize orientationchange', function() {
      $('.product__slider').slick('resize');
    });
    $(".js-menu-button").on("click", function() {
      $(this).toggleClass('active');
      $(this).next().toggleClass('active');
    });

    // 言語切り替え
    $('.l-navi__langList').hide();
    $('.l-navi__language').on('click', function(){
      $(this).next().slideToggle();
    });

  }); 



}